var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{ref:"container",staticClass:"py-0 px-0",attrs:{"fluid":""}},[_c('v-tabs',{ref:"tabs",attrs:{"active-class":"white","background-color":"grey lighten-3","hide-slider":""}},[_c('v-tab',{attrs:{"to":{
                name: 'main-work-orders',
            }}},[_vm._v(" WORK ORDERS ")]),_c('v-tab',{attrs:{"to":{
                name: 'approval-wo',
            }}},[_vm._v(" APPROVAL WO ")]),(_vm.approvedPermission)?_c('v-tab',{attrs:{"to":{
                name: 'approved-wo',
            }}},[_vm._v(" APPROVED WO ")]):_vm._e(),_c('v-tab',{attrs:{"to":{
                name: 'approval-bom',
            }}},[_vm._v(" APPROVAL BOM ")]),_c('v-tab',{attrs:{"to":{
                name: 'approved-bom',
            }}},[_vm._v(" APPROVED BOM ")]),_c('v-tab',{attrs:{"to":{
                name: 'closed',
            }}},[_vm._v(" CLOSED ")]),_c('v-tab',{attrs:{"to":{
                name: 'canceled',
            }}},[_vm._v(" CANCELED ")])],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"overflow-y-auto px-4 pt-4",style:(`height: ${_vm.height}px`)},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }